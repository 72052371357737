@import 'config.scss';
@import 'marketplace/styles/Header/index.scss';

.Mobile {
  display: flex;
  align-items: center;
  z-index: 100;
  height: 80px;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateZ(1000px);
}

.overlay {
  position: fixed;
}

.logo {
  width: 106px;
}

.burgerButton {
  width: 44px;
  height: 44px;
  padding: 0;
}

.burgerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 21px;
}

.dark .burger rect {
  fill: $purple-500;
}

.nav {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search {
  position: absolute;
  right: 0;
  margin-right: 50px;
}
.launchWeek {
  color: #C0C0CF;
  width: 16px;
  width: 16px;
  margin: auto;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 1px;
  cursor: pointer;
  
  path {
    fill:#C0C0CF;
  }
}
.icon {
  width: 50px;
  height: 50px;
  margin: auto;
  margin-top: 4px;
}