@import 'config.scss';

.LinksSection {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-200;
  padding: 56px 32px 32px 32px;
  width: 33%;

  &:last-child {
    border-right: none;
  }
}

.title {
  margin-bottom: 42px;
  font-weight: $bold;
  text-transform: uppercase;
  color: $grey-600;
  padding-left: 24px;
}

.linkContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.linkDiv {
  width: fit-content;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  a {
    padding: 10px;
  }
}

.linkIcon {
  width: 42px;
  height: 42px;
  position: relative;
  overflow: hidden;
}

.buttonLink {
  padding-left: 24px;
}
