@import 'config.scss';

.PopIn {
  visibility: hidden;
  position: absolute;
  top: 24px;
  left: 24px;
  transform: translateZ(1000px);
  z-index: 999;
}
