@import 'config.scss';

.SmallArrow {
  width: 11px;
  height: 12px;
  transform-origin: center;
}

// LAYOUT VARIANTS

.left {
  transform: rotate(180deg);
}

.down {
  transform: rotate(90deg);
}

// THEMES

.gray path {
  fill: $waikawa-gray;
}

.white path {
  fill: $white;
}

.green path {
  fill: $pastel-green;
}

.purple path {
  fill: $purple-500;
}

.bay-blue path {
  fill: $bay-blue;
}

.blue-800 path {
  fill: $blue-800;
}

.current-color path {
  fill: currentColor;
}

.blue-500 path {
  fill: $blue-600;
}

.grey-900 path {
  fill: $grey-900;
}
