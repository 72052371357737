@import 'config.scss';

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

#cb-master-frame {
  height: 0 !important;
}

.twitter-tweet {
  border-width: 0px;
  max-width: unset;
  min-width: unset;
  width: 100%;
}

.App {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backToList {
  margin-bottom: 30px;
}

.container {
  padding: 140px 0 0;

  @include breakpoint($md) {
    padding: 170px 0 0;
  }
}
