@import 'config.scss';

//////////////////////////
//     PRIMARIES        //
//////////////////////////

.white {
  color: $white;
}

.gray {
  color: $chambray;
}

.bay-blue {
  color: $bay-blue;
}

.waikawa-gray {
  color: $waikawa-gray;
}

.royal-blue {
  color: $royal-blue;
}

.blue {
  color: $scooter-blue;
}

.blue-500 {
  color: $blue-600;
}

.blue-800 {
  color: $blue-800;
}

.blue-900 {
  color: $blue-900;
}

.orange {
  color: $golden-orange;
}

.chambray {
  color: $chambray;
}

.yellow {
  color: $golden-yellow;
}

.green {
  color: $pastel-green;
}

.red {
  color: $bittersweet;
}

.purple {
  color: $purple-500;
}

.lynch {
  color: $waikawa-gray;
}

.blue-haze {
  color: $blue-haze;
}

.selago {
  color: $selago;
}

.grey-100 {
  color: $grey-100;
}

.grey-400 {
  color: $grey-400;
}

.grey-500 {
  color: $grey-500;
}

.grey-600 {
  color: $grey-600;
}

.grey-900 {
  color: $grey-900;
}

.neutral-500 {
  color: $neutral-500;
}

.neutral-600 {
  color: $neutral-600;
}

.purple-anemone {
  color: $purple-anemone;
}

//////////////////////////
//       THEMES         //
//////////////////////////

.dark {
  color: $bay-blue;
}

.bay-of-many {
  color: $bay-of-many;
}