@import 'config.scss';

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 32px;

  @include breakpoint($md) {
    padding: 50px 120px;
  }
}

.header {
  display: flex;
  align-items: center;
}

.title {
  font-size: $title-medium;
  font-weight: $bold;
  margin-bottom: 16px;
  line-height: normal;

  @include breakpoint($md) {
    width: 480px;
  }
}

.subtitle {
  font-size: $title-small;
  font-weight: $normal;
}

.content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  
  @include breakpoint($md) {
    gap: 60px;
  }
}

.content__container {
  grid-column: span 12;

  @include breakpoint($md) {
    grid-column: span 8;
  }

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.orderSuccess {
  padding: 0;
  border-radius: 0;
  border: 0;
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoint($md) {
    grid-column: span 8;
  }

  &__title {
    font-size: $text-large;
    font-weight: 600;
  }
}

.securityText{
  display: contents;
  font-size: $text-small-mb;
  b {
    font-weight: 500;
  }
}

.licenseKey {
  padding: 32px;
  border-radius: 6px;
  grid-column: span 12;

  @include breakpoint($md) {
    grid-column: span 8;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid var(--Neutral-Neutral-300, #DCDCE4);

  &__title {
    font-size: $text-smaller;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__text {
    font-size: $title-small;
    font-family: Menlo;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    transition: all 0.3s ease;
  }

  &__text.expanded {
    -webkit-line-clamp: unset;
    line-clamp: unset;
    max-height: none;
  }
}

.agreement {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__text {
    font-size: $text-small;
    font-weight: $normal;
  }

  a {
    color: var(--Neutral-700, #666687);
  }
}

.info {
  grid-column: span 12;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  max-height: fit-content;
  
  @include breakpoint($md) {
    grid-column: span 4;
    margin-top: 0;
  }

  padding: 32px;
  border-radius: 6px;
  border: 1px solid var(--Blue-Blue-300, #DADAFF);
  background: var(--Blue-Blue-100, #F6F6FF);

  &__title {
    color: $blue-500;
    text-transform: uppercase;
    font-weight: $bold;
    font-size: small;
  }

  &__text {
    font-size: $text-small;
    font-weight: $normal;
    line-height: 24px;
  }

  .list {
    list-style: decimal;
    gap: 12px;

    li {
      color: $blue-900;
      margin-bottom: 12px;
    }
  }

  a {
    color: $blue-900;
  }
}

.menloFont {
  font-family: Menlo, monospace;
}

.strapiLicense {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--Blue-Blue-400, #BBF);
  background: var(--Blue-Blue-200, #E9E9FF);
}

.buttons {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;

  &__right {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;

    @include breakpoint($xs) {
      margin-top: 0;
      flex-wrap: nowrap;
    }
  }

  @include breakpoint($xs) {
    flex-wrap: nowrap;
  }
}

.toggleButton,
.copyButton,
.downloadButton {
  color: #232285;
  border-radius: 6px;
  
  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.buttonIcon {
  margin-right: 10px;
  margin-left: -10px;
}

.loadingIcon {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    will-change: transform;
  }
}

.orderSummary {
  grid-column: span 12;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 32px;
  border-radius: 6px;
  border: 1px solid var(--Neutral-Neutral-200, #EAEAEF);

  @include breakpoint($md) {
    grid-column: span 4;
    margin-top: 0;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: $text-smaller;
    line-height: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-weight: 600;
    line-height: 27px;
  }
}