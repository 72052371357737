@use 'sass:math';

@import 'config.scss';

.wrapper {
  position: relative;
  width: math.div(1200, 1440) * 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  z-index: 1;
}

.customWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 27px;
  padding-bottom: 27px;
}

.innerWrapper {
  position: relative;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($md) {
    width: 100 - math.div(1200 - 980, 1200) * 100%;
  }
}

.mobileInnerWrapper {
  composes: innerWrapper;
  width: 100 - math.div(1200 - 980, 1200) * 100%;
}

.mobileInnerWrapperLeftOnly {
  composes: mobileInnerWrapper;

  @include breakpoint($md) {
    margin-right: 0;
    max-width: 980px + (1200 - 980) * 0.5;
    width: 100 - math.div(1200 - 980, 1200) * 0.5 * 100%;
  }
}

.smallInnerWrapper {
  composes: innerWrapper;

  @include breakpoint($md) {
    width: 54%;
  }
}

.mediumInnerWrapper {
  position: relative;
  max-width: 866px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($md) {
    width: 100 - math.div(1200 - 866, 1200) * 100%;
    min-width: 800px;
  }
}

.articleWrapper {
  position: relative;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;

}

.maxWidth {
  position: relative;
  width: 100%;
  max-width: 1440px;

  margin: {
    left: auto;
    right: auto;
  }
}

.wrapperv4 {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}