@import 'config.scss';

/** COOKIEBOT OVERRIDES **/
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#CybotCookiebotDialog {
  width: 90% !important;
  border: none !important;
  box-shadow: 0px 4px 40px rgba(51, 49, 49, 0.15) !important;
  left: 50% !important;
  top: auto !important;
  bottom: 40px !important;
  transform: translateX(-50%) translateZ(1000px) !important;
  margin: auto !important;
  border-radius: 6px !important;
  padding: 20px !important;
  box-sizing: border-box !important;
  max-width: 590px !important;

  @include breakpoint($sm) {
    width: auto !important;
    left: 50px !important;
    transform: translateX(0) translateZ(1000px) !important;
    padding: 32px 32px !important;
  }
}

#CybotCookiebotDialogBody {
  border: none !important;
  max-width: 590px !important;
  font-family: 'Poppins', sans-serif !important;
  width: 100% !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative !important;
  padding: 0 !important;
}

#CybotCookiebotDialogPoweredbyLink {
  margin: 0 !important;
  right: 0 !important;
  top: 0 !important;
}

#CybotCookiebotDialogBodyContent,
#CybotCookiebotDialogBodyButtons {
  padding: 0 !important;
  width: 100% !important;
}

#CybotCookiebotDialogBodyContent {
  max-width: 465px !important;
}

#CybotCookiebotDialogBodyButtons {
  @include breakpoint($sm) {
    max-width: 465px !important;
  }
}

#CybotCookiebotDialogBodyContentTitle {
  font-size: 21px !important;
  color: $bay-blue !important;
  margin-bottom: 12px !important;
}

#CybotCookiebotDialogBodyContentText {
  font-size: 13px !important;
  color: $waikawa-gray !important;
  line-height: 18px !important;
  margin-bottom: 24px !important;
  width: 90% !important;

  @include breakpoint($sm) {
    width: 100% !important;
    padding-right: 60px !important;
    box-sizing: border-box !important;
  }
}

#CybotCookiebotDialogBodyButtons,
#CybotCookiebotDialogBodyButtonDecline,
#CybotCookiebotDialogBodyButtonAccept {
  margin: 0 !important;
}

#CybotCookiebotDialogBodyButtons {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  @include breakpoint($sm) {
    flex-direction: row !important;
  }
}

#CybotCookiebotDialogPoweredbyImage {
  margin: 0 !important;
  width: 30px !important;
  display: block !important;
  margin-left: auto !important;

  @include breakpoint($sm) {
    width: 40px !important;
  }
}

#CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogBodyButtonDecline {
  display: block !important;
  width: 100% !important;
  padding: 10px 16px !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  font-weight: $medium !important;
}

#CybotCookiebotDialogBodyButtonAccept {
  background-color: $purple-500 !important;
  color: $white !important;
  border: none !important;
  margin-top: 6px !important;

  @include breakpoint($sm) {
    margin-left: 6px !important;
    margin-top: 0 !important;
  }
}

#CybotCookiebotDialogBodyButtonDecline {
  border: solid 1px $purple-500 !important;
  background-color: transparent !important;
  color: $purple-500 !important;
}

#CybotCookiebotDialogBodyButtonDetails {
  display: none !important;
}
