@import 'config.scss';

.Push {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 344px;
  width: auto;
  text-decoration: none;

  & + .Push {
    display: none;

    @include breakpoint($xlg) {
      display: flex;
    }
  }

  .title {
    margin-bottom: 24px;
    font-weight: $semi-bold;
    text-decoration: none;
    text-align: left;
    font-size: 13px;
    text-transform: uppercase;
    color: $grey-600;

    &:hover {
      color: $bay-blue;
    }
  }
}

.cover {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 16px;

  img {
    /*css code*/
    object-fit: none;
    width: auto;
    height: auto;
  }
}

.textContent {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 15px;
    font-weight: 500;
    color: $grey-900;
    line-height: 24px;
  }

  .content {
    color: $grey-700;
    font-size: 13px;
    font-weight: 400;
  }
}

.link {
  margin-top: auto;
  text-decoration: none;
}
