@import 'config.scss';

.wrapper {
    position: relative;
    padding: 10px 16px;
    background-color: $blue-1000;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 6px;

    p {
        color: $white;
        font-size: $text-smaller;
        font-family: $monaco;
    }

    .copyIcon {
        &:after {
            content: 'Copy to clipboard';
            display: none;
            position: absolute;
            top: 40px;
            transform: translateX(-50%);
            width: 140px;
            color: #fff;
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 11px;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
            background: rgba(0, 0, 0, 0.72);
            border-radius: 3px;
            padding: 8px 0;
            z-index: 9999;
        }

        &:hover {

            &:before,
            &:after {
                display: block;
            }
        }

        &:focus {
            &:after {
                content: 'Copied!';
            }
        }
    }



}