@import 'config.scss';

$small-width: 20px;
$big-width: 23px;

.IconLink {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
  border-radius: 50%;
  width: $small-width;
  height: $small-width;

  &.big {
    width: 43px;
    height: 43px;
    background-color: $mystic-gray;

    .link {
      max-width: 100%;
      max-height: 100%;
    }

    .hoverIconWrapper,
    .iconWrapper {
      width: $big-width;
      height: $big-width;
    }
  }

  &:last-child {
    margin-right: 0px;
  }
}

.link {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $small-width;
  max-height: $small-width;
}

.hoverIconWrapper,
.iconWrapper {
  width: 100%;
  height: 100%;
  will-change: opacity;
}

.hoverIconWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  width: 100%;
  height: auto;
}
