@import 'config.scss';

.PrideMonthBanner {
  width: 100%;
  height: 6px;
  background: linear-gradient(
    90deg,
    #000 0,
    #926e64 11%,
    #ffb6e6 22%,
    #a3deff 33%,
    #fff 44%,
    red 55%,
    #ffe462 66%,
    #0acf83 77%,
    #1abcfe 88%,
    #a259ff
  );
}
