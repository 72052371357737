@import 'config.scss';

.InputText {
  width: calc(100% - 12px);
  display: flex;
  background-color: $white;
  border: 1px solid $purple-500;
  border-radius: 10px;
  padding: 6px;
  box-sizing: border-box;
  transition: ease 0.2s all;

  &:focus-within {
    background-color: $white;

    input {
      color: $bay-blue;

      &::placeholder {
        color: $bay-blue;
      }
    }
  }

  input {
    appearance: none;
    font-family: $poppins;
    font-size: 16px;
    font-weight: $normal;
    background-color: transparent;
    border-radius: 6px;
    border: 0;
    margin: 0 0 0 10px;
    padding: 0;
    outline: none;
    width: 100%;

    &::placeholder {
      color: $bay-blue;
      text-transform: capitalize;
    }

    @include breakpoint($md) {
      font-size: 13px;
    }
  }

  .icon {
    width: 40px;
    height: 40px;
  }
}
