@import 'config.scss';
@import 'marketplace/styles/Header/index.scss';

.largeScreenNav,
.smallScreenNav {
  position: fixed;

  &.hasStickyBar {
    height: 80px;
    position: relative;
  }
}

.largeScreenNav {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}

.smallScreenNav {
  @include breakpoint($md) {
    display: none;
  }
}
