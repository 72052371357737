@import 'config.scss';

.Panel {
  position: absolute;
  top: 100%;
  left: -16px;
  width: calc(100% + 32px);
  visibility: hidden;
  opacity: 0;
  will-change: opacity, transform;
  cursor: default;
  transform: translateZ(1000px);
}

.cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-top: 30px;
  border: 1px solid $grey-200;
  background: $white;
}

.card {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
}

.cardBackground {
  will-change: transform;
  transform-origin: top;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: $white;
  border-radius: 10px;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 2;
  position: relative;
}

.linkSections {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.linkSectionsHasPush {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  // gap: 24px 64px;
}

.pushs {
  position: relative;
  display: block;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 40px 40px 56px 40px;
  border-radius: 0 20px 20px 0;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  color: $grey-900;

  span {
    transition: color 0.3s ease;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    span {
      color: $purple-500;
    }

    .arrow {
      transform: translateX(5px);

      path {
        fill: $purple-500;
      }
    }
  }

  .arrow {
    margin-left: 10px;
    transform: translateX(0px);
    transition: transform 0.6s $easeOutExpo;
  }
}

.lowerLinks {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 6px;
  text-decoration: none !important;

  &:hover {
    span {
      color: $blue-600;
    }
  }
}

.lowerSection {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid $grey-200;
  background: $white;
  border-radius: 0 0 10px 10px;
  height: fit-content;
}

.lowerLinkItem {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 6px;
}

.linkIcon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

// .arrowLinks {
//   margin-top: 40px;
// }
