@import 'config.scss';

.Img {
  opacity: 0;
  visibility: hidden;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.top {
  object-position: top;
}

.center {
  object-position: center;
}

.left {
  object-position: left;
}

.right {
  object-position: right;
}
