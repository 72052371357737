@import 'config.scss';

.codeContainer {
  border-radius: 8px;
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  padding-bottom: 16px;

  @include breakpoint($md) {
    flex-direction: row;
    padding-bottom: 0;
    align-items: center;
  }

  .code {
    font-family: Monaco;
    font-size: 15px;
    line-height: 25px;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 16px 16px 16px 24px;
    font-weight: 400;

    @include breakpoint($md) {
      width: 100%;
    }
  }

  .copyButton {
    color: #ac73e6;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    padding-right: 24px;
    padding-left: 24px;
    // The 'copy' and 'copied!' is hardcoded
    // this will prevent layout shift
    width: 70px;
    overflow: visible;
    // -----
    @include breakpoint($md) {
      padding-left: 0;
    }
  }
}

.light {
  background-color: white;
  border: 1px solid #eaeaef;
  box-shadow: 0px 2px 4px 0px rgba(41, 40, 117, 0.08);

  .code {
    color: #32324d;
  }
}

.dark {
  background-color: #212134;
  border: 1px solid #32324d;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  .code {
    color: white;
  }
}
