@import 'config.scss';

.HeaderBackground {
  position: absolute;
  width: 100%;
  min-height: 80px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  border: 1px solid $zircon;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
  transition: opacity 0.1s linear;
  opacity: 0;

  &.darkMode {
    background-color: $black-dark-mode;
    border: none;
  }
  &.isLaunchWeek {
    background-color: $black-dark-mode;
    border: none;
  }
  &.hasScrolled {
    opacity: 1;
  }

  &.hasStickyBar {
    box-shadow: none;
  }
}

@include breakpoint($md) {
}
