@import 'config.scss';

.LogoPopin {
  padding: 40px;
  width: 360px;
  background-color: $white;
  border-radius: 14px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 2px 15px 0px rgba(117, 141, 166, 21.42%);
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.title {
  text-align: center;
  margin-bottom: 40px;
}

.image {
  max-width: 190px;
  margin: 0 auto 40px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .button:last-child {
    margin-top: 13px;
  }

  .button {
    display: block;
    width: 100%;
  }
}
