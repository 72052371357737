@import 'config.scss';

.wrapper {
  display: flex;
  padding: 32px;
  max-width: 1200px;
  gap: 56px;
  margin: 0;
  flex-direction: column;

  @include breakpoint($md) {
    padding: 120px 0;
    gap: 80px;
    margin: 0 auto;
    flex-direction: row;
    margin-bottom: 100px;
  }
}
.leftSide {
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: center;
  @include breakpoint($md) {
    width: 50%;
    align-items: flex-start;
  }
}
.rightSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  @include breakpoint($md) {
    width: 50%;
    gap: 56px;
    align-items: flex-start;
  }
}

.title {
  color: $blue-800;
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  @include breakpoint($md) {
    font-size: 33px;
    line-height: 49px;
  }
}

.contentColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.featuresRow {
  flex-wrap: wrap;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include breakpoint($md) {
    flex-direction: row;
    margin-top: 8px;
  }
}
.oneFeature {
  display: flex;
  gap: 6px;
  align-items: center;
}
.featureIcon {
  position: sticky;
  width: 20px;
  height: 20px;
}
.featureTitle {
  color: $blue-800;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}
.scrollWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include breakpoint($md) {
    width: fit-content;
  }
}
.logosRow {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 16px;
  justify-items: center;

  @include breakpoint($md) {
    flex-wrap: wrap;
    gap: 40px;
    display: flex;
    width: 100%;
    flex-direction: row;
  }
}
.logo {
  position: sticky;
  display: flex;
  height: 32px;

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.linksWrapper {
  display: flex;
  gap: 24px;

  @include breakpoint($md) {
    flex-direction: row;
    gap: 32px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include breakpoint($md) {
    gap: 8px;
  }
}
.border {
  display: inline;
  width: 4px;
  align-self: stretch;
  border-radius: 9999px;
  background: $neutral-150;
}
.titleRow {
  display: flex;
  gap: 12px;
  flex-direction: column;

  @include breakpoint($md) {
    flex-direction: row;
  }
}
.linkTitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  color: $blue-800;

  @include breakpoint($md) {
    font-size: 21px;
    line-height: 31px;
  }
}
.linkDescription {
  color: $grey-700;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  @include breakpoint($md) {
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 16px;
  }
}
.linkImage {
  position: sticky;
  height: 32px;
  width: 32px;

  @include breakpoint($md) {
    height: 24px;
    width: 24px;
  }
}
.actionLink {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: var(--Blue-Blue-600, #4945ff);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  transition: color 0.3s ease;
}

.arrow {
  transition: transform 0.3s ease;
}

.actionLink:hover .arrow {
  transform: translateX(5px);
}
