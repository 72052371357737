@import 'config.scss';

#hubspot-messages-iframe-container {
  transform: translateZ(1000px);
}

#cb-container {
  transform: translateZ(1001px);
}

#cb-master-frame {
  display: none !important;
}
