.marketplaceLogo {
  display: block;
  width: 112px;

  @include breakpoint($lg) {
    width: 133px;
  }

  @include breakpoint($xlg) {
    width: 199px;
  }
}

.marketplaceLogo_mobile {
  width: 183px;
}

.marketplaceLargeScreenNav,
.marketplaceSmallScreenNav {
  position: fixed;

  &.hasStickyBar {
    height: 80px;
    position: relative;
  }
}

.marketplaceLargeScreenNav {
  display: none;

  @include breakpoint($md) {
    display: block;
    border-bottom: 1px solid $grey-100;
    padding-bottom: 16px;
  }
}

// Reset styles on scroll
header.hasScrolled {
  border-bottom: none;
  padding-bottom: 0px;
}

.marketplaceSmallScreenNav {
  @include breakpoint($md) {
    display: none;
  }
}
