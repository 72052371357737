@import 'config.scss';

.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;

    padding: 20px 30px;
    gap: 16px;
  
    @include breakpoint($md) {
      padding: 50px 160px;
    }
  }
  
  .notFound__title {
    color: var(--Neutral-Neutral-900, $blue-dark-mode);
    text-align: center;
    font-size: $title-medium;
    font-style: normal;
    font-weight: $bold;
    line-height: 49px; 
  }
  
  .notFound__message {
    color: var(--Neutral-Neutral-900, $blue-dark-mode);
    text-align: center;
    font-size: $text-large;
    font-weight: $normal;
    line-height: 31px; 
    margin-bottom: 16px;
  }

  .contactSupportButton {
    margin-top: 16px;
  }

  .retryButton {
    button {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }