@import 'config.scss';

.LinksSection {
  border-right: 1px solid $grey-200;
  padding: 56px 32px 32px 32px;
  flex: auto;

  &:last-child {
    border-right: none;
    border-radius: 0 10px 0 0;
  }

  &:first-child {
    border-radius: 10px 0 0 0;
  }
}

.LinksSectionWide {
  width: 66%;
}

.LinksSectionNarrow {
  width: 33%;
}

.LinksSectionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 10px;
}

.LinkSectionColumn {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 24px;
  font-weight: $bold;
  text-transform: uppercase;
  color: $grey-600;
  padding-left: 24px;
}
