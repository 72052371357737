@import 'config.scss';

.NavigationLabel {
  display: flex;
  align-items: center;
  height: 55px;

  button {
    border: 0;
    padding: 0;
    background: none;
  }

  .wrapper {
    display: flex;
    align-items: center;
    text-align: left;
    text-decoration: none;
    cursor: default;
    padding: 5px 3.5px 5px 3.5px;

    @include breakpoint(1050) {
      padding: 5px 6px 5px 8px;
    }

    @include breakpoint(1120) {
      padding: 5px 8px 5px 12px;
    }

    @include breakpoint($lg) {
      padding: 5px 8px 5px 12px;
    }
  }

  .wrapper-dark {
    cursor: pointer;
    color: $grey-200;

    &:hover {
      background: #1c1c4e;
      border-radius: 4px;
    }
  }

  .wrapper-lightGrey {
    cursor: pointer;
    color: $grey-400;

    &:hover {
      color: $blue-600;
      border-radius: 4px;
      background: #f6f6ff;
    }
  }
  .wrapper-light {
    cursor: pointer;
    color: $grey-900;

    &:hover {
      color: $blue-600;
      border-radius: 4px;
      background: #f6f6ff;
    }
  }

  .label {
    position: relative;
    transition: color 0.4s ease;

    .labelText {
      height: 50px;
      font-weight: $medium;
      font-size: 12px;

      @include breakpoint($lg) {
        font-size: 13px;
      }

      @include breakpoint($xlg) {
        font-size: 15px;
      }

      transition: color 0.1s linear;

      span {
        vertical-align: middle;
      }

      .arrow {
        position: relative;
        bottom: 1.5px;
        margin-left: 6px;
        border: solid currentColor;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(45deg);

        @include breakpoint($lg) {
          bottom: 2px;
        }

        @include breakpoint($xlg) {
          bottom: 0.5px;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .labelTextLine {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $white;

      &.dark {
        background-color: $purple-500;
      }
    }

    .labelTextLineContainer {
      position: absolute;
      width: 100%;
      height: 2px;
      overflow: hidden;
      bottom: -5px;
    }
  }

  @include breakpoint($md) {
    padding: 0 6px;
  }
}

.newPricing {
  color: $lavender-300;
  font-size: $text-legend;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  left: 26px;
}

.newPricingDark {
  color: $purple-600;
  font-size: $text-legend;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  left: 26px;
}

// End of to be removed
