@import 'config.scss';

.MenuColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  max-width:125px;
  min-width:120px;
  box-sizing: border-box;

  .sectionTitle {
    margin-bottom: 30px;
    font-weight: $semi-bold;
    text-transform: uppercase;
  }

  @include breakpoint($md) {
    min-width: none;
    max-width: 135px;
  }
}

