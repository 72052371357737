@import 'config.scss';

.DesignStudio {
  display: flex;
  align-items: flex-end;

  .logo {
    margin-left: 3px;
    max-width: 45px;
    max-height: 15px;
  }
}
