@import 'config.scss';

.link {
  display: flex;
  align-items: center;
  color: $waikawa-gray;

  span {
    transition: color 0.3s ease;
  }

  a {
    text-decoration: none;
    width: 100%;
  }

  .linkItemWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 24px 20px 24px;

    .linkIcon {
      width: 20px;
      height: 20px;
      position: relative;
      overflow: hidden;
    }

    .linkItemTextWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .linkTitleWrapper {
        gap: 8px;
        display: flex;
        align-items: center;

        .linkTitle {
          font-weight: 500;
        }

        .linkLoadingText {
          background: #e9e9ff;
          border-radius: 4px;
          color: $blue-600;
          font-style: italic;
          font-weight: 500;
          font-size: 12px;
          margin-left: 8px;
          padding: 2px 4px;
          gap: 8px;
        }

        .linkTitleArrow {
          margin-left: 10px;
          // transform: translateX(0px);
          // transition: transform 0.6s $easeOutExpo;
        }
      }

      .linkSummary {
        font-weight: 400;
        color: $grey-700;
      }
    }
  }

  &:hover {
    .linkItemWrapper {
      background: #f6f6ff;
      border-radius: 10px;

      .linkTitle {
        color: $blue-600;
      }
    }

    .linkTitleArrow {
      // transform: translateX(5px);

      path {
        fill: $blue-600;
      }
    }
  }

  &:focus {
    border-radius: 4px;
    border: 2px solid #271fe0;
  }

  @media (min-width: '769px') {
    &:last-child {
      margin-bottom: 0px;
    }
  }

  @media (max-width: '768px') {
    padding-right: 27px;
  }
}
