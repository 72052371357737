@import 'config.scss';

.MenuColumnLink {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.link {
  composes: FooterLink from '../../../typo/typography.module.scss';
  color: $waikawa-gray;

  &.darkMode {
    color: $grey-400;
  }
}

.tag {
  background-color: $lavender-500;
  color: $white;
  font-size: $text-extra-small;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 55px;
  position: absolute;
}
