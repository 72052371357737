@import 'config.scss';

.SocialLinks {
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  .text {
    margin-right: 16px;
  }
}
