@import 'config.scss';

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  color: $grey-900;

  .arrowTitle {
    margin-bottom: 24px;
    font-weight: $bold;
    text-transform: uppercase;
    color: $grey-600;
  }

  .linkWrapper {
    display: flex;
    align-items: center;
  }

  .arrowLink {
    font-weight: 600;
    color: $grey-900;
  }

  .iconList {
    list-style: none;
    margin-right: 16px;

    li.arrowIcon {
      float: left;
      padding: 6px 8px;
      margin-right: 8px; 
    }
  }
  
  span {
    transition: color 0.3s ease;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    span {
      color: #635cff;
    }
    .arrow {
      transform: translateX(5px);

      path {
        fill: #635cff;
      }
    }
  }

  .arrow {
    margin-left: 10px;
    transform: translateX(0px);
    transition: transform 0.6s $easeOutExpo;
    font-weight: 600;
    color: $grey-900;
  }
}

.arrowLinks {
  margin-top: 40px;
}