@import 'config.scss';

.container {
  display: flex;
  align-items: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.ghLink {
  color: white;
  text-decoration: none;
}

.ghLinkDark {
  color: #32324d;
  text-decoration: none;
}

.launchWeek {
  color: $grey-400;
  text-decoration: none;
}