@import 'config.scss';

.Footer {
  padding-bottom: 25px;
  background: linear-gradient(359.75deg, $zircon -63.96%, $white 55.16%);

  @include breakpoint($md) {
    padding-bottom: 60px;
  }

  &.darkMode {
    background: linear-gradient(0deg,
        $black-dark-mode 0%,
        $blue-dark-mode 100%);

    margin-top: 80px;

    @include breakpoint($md) {
      margin-top: 136px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;

  @include breakpoint($sm) {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 120px;
  }
}

.leftContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;

  .logo {
    flex: 1 0 auto;
    display: block;
    width: 120px;

    img {
      width: 100%;
    }
  }

  .badges {
    margin-top: 40px;
  }

  .badge {
    padding-right: 16px;
    height: 64px;
    width: 64px;
  }

  .tagline {
    margin-left: 40px;
  }

  @include breakpoint($sm) {
    flex-direction: column;
    padding-right: 75px;

    .tagline {
      margin-left: auto;
      margin-top: 40px;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  gap: 3rem;
  margin-top: 40px;

  @include breakpoint($sm) {
    margin-top: 0;
    gap: 2.5rem;
    flex: 4;
  }
}

.legals {
  display: flex;
  width: 100%;
  border-top: 1px solid $mystic-gray;
  padding-top: 30px;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column-reverse;

  &.darkMode {
    border-top: 1px solid $grey-800;
  }

  @include breakpoint($sm) {
    flex-direction: row;
  }
}

.subLinks {
  display: flex;
  flex: 1;
  margin-top: 20px;

  @include breakpoint($sm) {
    margin-top: 0px;
    flex-direction: row;
  }
}

.subLink {
  text-decoration: none;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  @include breakpoint($sm) {
    margin-right: 30px;
  }
}