@import 'config.scss';

.FullWidthCTABanner {
  position: relative;
  padding: 175px 0 100px 0;

  @include breakpoint($sm) {
    padding: 175px 50px 100px 50px;
  }

  &.darkMode {
    padding-top: 144px;
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;

  @include breakpoint($md) {
    width: 300px;
  }
}

.title {
  text-align: center;
}

.actionsWithCli {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin-top: 45px;

  @include breakpoint($sm) {
    align-items: center;
    flex-direction: row;
  }
  @include breakpoint($md) {
    display: none;
  }
}

.actionsWithoutCli {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin-top: 25px;

  @include breakpoint($sm) {
    align-items: center;
    flex-direction: row;
  }
}

.button {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  text-align: center;
  transform: translateZ(1000px);

  @include breakpoint($sm) {
    width: auto;
    max-width: none;
    margin: 0;
  }

  & + .button {
    margin-top: 20px;

    @include breakpoint($sm) {
      margin-top: auto;
      margin-left: 40px;
    }
  }

  a {
    width: 100%;
    box-sizing: border-box;
  }
}

.code {
  display: none;
  position: relative;
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  margin: auto;
  margin-top: 20px;

  @include breakpoint($md) {
    display: block;
    order: 3;
  }

  pre {
    -ms-overflow-style: none !important;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  &:before {
    content: '';
    display: none;
    position: absolute;
    z-index: 9998;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.72);
  }

  &:after {
    content: 'Copy to clipboard';
    display: none;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    color: #fff;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.72);
    border-radius: 3px;
    padding: 8px 0;
    z-index: 9999;
  }

  &:hover {

    &:before,
    &:after {
      display: block;
    }
  }

  &:focus {
    &:after {
      content: 'Copied!';
    }
  }
}
