@import 'config.scss';

.buttonContainer {
  display: inline-block;
  .button {
    background-color: transparent;
  }

  &.withFocus {
    .background {
      border: solid 1px $input-purple;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.button {
  position: relative;
  display: inline-block;
  background-color: transparent;
  width: 100%;
  transform: translateZ(900px);

  @include breakpoint($sm) {
    width: auto;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.fullWidth {
    display: block;
  }
}

.hoverCircle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.buttonLink {
  color: inherit;
  position: relative;
  background-color: transparent;
  composes: Button from '../../typo/typography.module.scss';
  cursor: pointer;
  display: inline-block;
  padding: 18px 40px;
  border: none;
  text-decoration: none;
  text-align: center;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  transform: translateZ(1000px);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.background,
.shadow {
  border-radius: 8px;
}

.background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
  border: solid 1px transparent;
  transition: ease 0.2s border;
  box-sizing: border-box;
}

.shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
}

.small {
  composes: SmallButton from '../../typo/typography.module.scss';
}

.small-padding {
  .buttonLink {
    padding: 15px 14px;
  }
}

// THEMES
.purple {
  background-color: $purple-500;
  color: $white;
}

.link {
  background-color: transparent;
  border: none;
  color: $blue-600;

  .buttonLink {
    text-align: left;
    padding: 0;
    font-family: Poppins;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $blue-600;
    &::after {
      padding-left: 8px;
      content: url(/assets/decoration/arrow-right.svg);
    }
  }

  .shadow {
    display: none;
  }

  .background {
    box-shadow: none;
  }
}

.yellow {
  background-color: $golden-yellow;
  color: $jacksons-purple;
}

.orange {
  background-color: $golden-orange;
  color: $white;
}

.white {
  background-color: $white;
  color: $blue-800;
}

.white-green {
  background-color: $white;
  color: $salem;
}

.gray {
  background-color: $white;
  color: $waikawa-gray;
}

.green {
  background-color: $pastel-green;
  color: $white;
}

.blue {
  background-color: $blue-600;
  color: $white;
}

.lavender {
  background-color: $purple-500;
  color: $white;
}

.lavender-500 {
  background-color: $lavender-500;
  color: $white;
}

.blue-500 {
  background-color: $blue-600;
  color: $white;
}

.white-gray {
  background-color: $grey-100;
  color: $grey-900;
}

.white-blue-500 {
  background-color: $white;
  color: $blue-600;
}

.transparent-blue-500 {
  background-color: transparent;
  color: $blue-600;
}

.darkBlue {
  background-color: $governor-bay;
  color: $white;
}

.gradientPurple {
  background-color: $purple-600;
  color: $white;
}

.gradientOrange {
  background-color: $tuscany;
  color: $white;
}

.gradientBlue {
  background-color: $cobalt-dark;
  color: $white;
}

.gradientGreen {
  background-color: $salem;
  color: $white;
}

.marketplaceWhite {
  background-color: $white;
  color: $blue-800;
}

.marketplaceBlue {
  background-color: $blue-600;
  color: $white;
}
