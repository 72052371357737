@import 'config.scss';

.ratio {
  position: relative;
  width: 100%;

  &.fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.notAbsoluteFluid {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.notAbsoluteImg {
  top: 0;
  position: relative; 
  left: 0;
  width: 100%;
  height: 100%;
}
.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
