@import 'config.scss';

.Separator {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 1px;
  margin: {
    left: auto;
    right: auto;
  }
  background-color: $mystic-gray;
}
