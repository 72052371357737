@import 'config.scss';

.Logo {
  display: block;
  position: relative;

  .img {
    display: block;
    width: 100%;
    height: 42px;
    transition: opacity 0.1s ease-in-out;
    &.darkMode {
      &.light {
        &.hasScrolled {
          opacity: 0;
        }
      }
      &.dark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        opacity: 0;
        &.hasScrolled {
          opacity: 1;
        }
      }
    }
  }
}
